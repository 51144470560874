/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import React, {Suspense, lazy, useEffect} from "react";
import amplitude, { Config } from "amplitude-js";
import { AmplitudeProvider } from "react-amplitude-hooks";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import {QueryClient, QueryClientProvider, QueryErrorResetBoundary} from "react-query";
import { ErrorBoundary } from 'react-error-boundary';
import { ReactQueryDevtools } from "react-query/devtools";
import * as url from "url";
import { useAnalytics } from "./hooks/useAnalytics";
import {useURLSearchParams} from "./hooks/useURLSearchParams";
import {LineLoginError, useGetLineUser} from "./hooks/useGetLineUser";
import {Spinner} from "./components/Loading";

const EntryPage = lazy(() => import("./pages/entry"));
const ProfilePage = lazy(() => import("./pages/profile"));
const ResultPage = lazy(() => import("./pages/result"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3
    }
  }
});

const AmplitudeConfig: Config = {
  deviceIdFromUrlParam: true,
  includeGclid: true,
  includeUtm: true,
  includeReferrer: true
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <HelmetProvider>
        <div className="App">
          <QueryErrorResetBoundary>
            {({ reset }) => (
                <ErrorBoundary onReset={reset} fallbackRender={({ resetErrorBoundary, error }) => {
                  return (error instanceof LineLoginError) ? <h1>LINEログインが必要です</h1> : null;
                }}>
                  <Suspense fallback={<Spinner active />}>
                    <Router>
                      <Routing />
                    </Router>
                  </Suspense>
                </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>

        </div>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

function Routing() {
  const {
    query: { application }
  } = url.parse(window.location.href, true);
  const location = useLocation();
  const params = useURLSearchParams();
  const redirectTo = params.get('liff.state');
  const { data: user } = useGetLineUser();
  const { initialize } = useAnalytics();
  console.log(`Welcome to ${user.display_name} !`);
  useEffect(() => {
    application && initialize();
  }, [application])
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return (
      <div className={`is-app-${application}`}>
        <AmplitudeProvider
          amplitudeInstance={amplitude.getInstance()}
          apiKey={process.env.REACT_APP_AMPLITUDE_API_KEY ?? ""}
          config={AmplitudeConfig}
        >
          <Switch>
            <Route path="/entry" children={<EntryPage />} />
            <Route path="/profile" children={<ProfilePage />} />
            <Route path="/result" children={<ResultPage />} />
            <Redirect to={{ ...location, pathname: `/entry` }} />
          </Switch>
        </AmplitudeProvider>
      </div>
  );
}

export default App;
